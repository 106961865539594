import clsx from 'clsx';
import { memo, useEffect } from 'react';
import { VirtualItem } from '@tanstack/react-virtual';
import { TableBody, TableRow, Theme, makeStyles } from '@material-ui/core';
import { IntersectionOptions, useInView } from 'react-intersection-observer';

interface SInfiniteScrollProps<T> {
  id?: string;
  list: T[];
  vList: VirtualItem<any>[];
  height: number;
  options?: Record<string, string | number>;
  children?: JSX.Element;
  containerClass?: string;
  wrapperClass?: string;
  intersectionOptions?: IntersectionOptions;

  renderDragItem?: (element: T) => Element;
  onWrapperClick?: (element: T) => void;
  onWrapperDrag?: (element: T) => void;
  onWrapperDrop?: (element: T) => void;
  fetchMore?: () => void;
  keyGetter: (element: T) => string | number;
  render: (element: T, index?: number) => JSX.Element;
}

export const useStyles = makeStyles<
  Theme,
  { wrapperClick: boolean; height: number }
>({
  wrapper: {
    cursor: ({ wrapperClick }) => wrapperClick && 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E6E7E9'
  },
  tbody: {
    display: 'table-row',
    height: ({ height }) => (height ? `${height}px` : '100%'),
    width: '100%',
    position: 'relative'
  }
});

const VirtualList = <T,>({
  id,
  list,
  vList,
  height,
  options,
  children,
  wrapperClass,
  containerClass,
  intersectionOptions,
  render,
  fetchMore,
  keyGetter,
  onWrapperDrop,
  onWrapperDrag,
  onWrapperClick,
  renderDragItem
}: SInfiniteScrollProps<T>) => {
  const classes = useStyles({ wrapperClick: !!onWrapperClick, height });
  const { ref, inView } = useInView(intersectionOptions);

  useEffect(() => {
    if (inView) {
      fetchMore?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <TableBody id={id} className={clsx(classes.tbody, containerClass)}>
      {vList.map(virtualRow => {
        const isLoaderRow = virtualRow.index > list?.length - 1;
        const element = list[virtualRow.index];

        if (!element) return null;

        return (
          <TableRow
            key={keyGetter(element)}
            onClick={() => onWrapperClick?.(element)}
            draggable={!!onWrapperDrag}
            onDragStart={() => onWrapperDrag?.(element)}
            className={clsx(classes.wrapper, wrapperClass)}
            onDragOver={e => e.preventDefault()}
            onDragStartCapture={e => {
              const div = renderDragItem?.(element);
              if (div) e.dataTransfer.setDragImage(div, 0, 0);
            }}
            onDrop={ev => {
              ev.preventDefault();
              ev.stopPropagation();
              onWrapperDrop?.(element);
            }}
            style={{
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start - ((options?.scrollMargin as number) || 0)}px) translateX(0px)`
            }}
            ref={virtualRow?.index + 1 === list?.length ? ref : null}>
            {isLoaderRow
              ? 'Loading more...'
              : render(element, virtualRow.index)}
          </TableRow>
        );
      })}
      {children}
    </TableBody>
  );
};

const SVirtualList = memo(VirtualList) as typeof VirtualList;
export default SVirtualList;
