import { Box, Button } from '@material-ui/core';
import clsx from 'clsx';
import { ReactNode, MouseEvent } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useCustomSelectButtonStyles } from './style';
import SText from '../../../../sui/stext';
import { TextColor } from '../../../../../enums';

interface SSelectDetailsButtonProps {
  width?: number;
  title?: string;
  titleHighligted?: boolean;
  label?: string;
  icon?: ReactNode;
  open?: boolean;
  noBorder?: boolean;
  renderTitle?: (title: string) => JSX.Element;

  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const SSelectDetailsButton = ({
  title,
  titleHighligted,
  renderTitle,
  open,
  icon,
  label,
  onClick,
  width,
  noBorder = false
}: SSelectDetailsButtonProps) => {
  const classes = useCustomSelectButtonStyles({ open, width, noBorder });

  return (
    <Button className={classes.container} onClick={onClick}>
      <Box className={classes.valueWrapper} display="flex" alignItems="center">
        {label && <SText title={`${label} :`} />}
        {renderTitle ? (
          renderTitle(title)
        ) : (
          <SText
            className={clsx({
              [classes.labelDetails]: true,
              [classes.labelHighlight]: titleHighligted
            })}
            title={title}
            fontColor={TextColor.Grey}
          />
        )}
      </Box>
      <Box className={classes.iconContainer}>
        {icon || <ArrowForwardIosIcon />}
      </Box>
    </Button>
  );
};

export default SSelectDetailsButton;
