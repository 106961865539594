import { CSSProperties, useMemo } from 'react';
import { Typography, withStyles, TableCell, Tooltip } from '@material-ui/core';

import { useStyles } from '../../../styles';
import { ColumnsType } from '../../../../../types';
import SHighlightText from '../../../../shighlight-text';
import { TextColor } from '../../../../../enums';
import { SCheckbox } from '../../../../scheckbox';
import { distances } from '../../../../../styles';

export const STableCell = withStyles(({ breakpoints }) => ({
  root: {
    padding: distances.px.small,
    overflow: 'hidden',
    maxHeight: '55px',
    cursor: 'inherit',
    border: 'none',

    [breakpoints.down(1500)]: {
      minWidth: ({ minWidth }: any) => minWidth || 150
    },
    [breakpoints.down(1810)]: {
      minWidth: ({ minWidth }: any) => minWidth || 180
    }
  }
}))(TableCell);

export interface TableCellsProps {
  columns: ColumnsType;
  rowData: any;
  /**
   * @deprecated use checkbox inside row instead
   */
  isSelectable?: boolean;
  loading?: boolean;
  /**
   * @deprecated use checkbox inside row instead
   */
  selectedItems?: any[];
  /**
   * @deprecated use checkbox inside row instead
   */
  onSelectItem?: (isChecked: boolean, data: any) => void;
  rowKeyGetter: (_data: any) => string | number;
  searchQuery?: string;
  /**
   * @deprecated use checkbox inside row instead
   */
  selectDisabled?: (row: any) => boolean;
  /**
   * @deprecated use checkbox inside row instead
   */
  overLimit?: (row: any) => boolean;
  /**
   * @deprecated use checkbox inside row instead
   */
  selectDisabledText?: string;
  style?: CSSProperties;
}

const TableBodyCells = ({
  columns,
  rowData,
  searchQuery,
  loading = false,
  isSelectable = false,
  selectedItems,
  selectDisabledText,
  rowKeyGetter,
  overLimit,
  onSelectItem,
  selectDisabled,
  style = {}
}: TableCellsProps) => {
  const classes = useStyles({ maxWidth: 40, minWidth: 40 });

  const tableCellCheckbox = useMemo(() => {
    const isSelected = selectedItems?.some(
      item => rowKeyGetter(item) === rowKeyGetter(rowData)
    );

    const isOverLimit = overLimit?.(rowData) && !isSelected;

    const isDisabled = selectDisabled?.(rowData) || isOverLimit;

    return (
      <STableCell
        style={{
          textAlign: 'center',
          minWidth: 40,
          maxWidth: 40,
          width: 40
        }}>
        <Tooltip
          title={selectDisabledText}
          disableHoverListener={!(isDisabled && selectDisabledText?.length > 0)}
          arrow>
          <span onClick={e => e.stopPropagation()}>
            <SCheckbox
              color="primary"
              checked={isSelected}
              onChange={e => onSelectItem(e.target.checked, [rowData])}
              onClick={e => e.stopPropagation()}
              disabled={isDisabled}
            />
          </span>
        </Tooltip>
      </STableCell>
    );
  }, [
    selectedItems,
    selectDisabled,
    rowData,
    overLimit,
    selectDisabledText,
    rowKeyGetter,
    onSelectItem
  ]);

  return (
    <>
      {isSelectable && tableCellCheckbox}
      {columns.map(column => (
        <STableCell
          key={column.id}
          align={column?.align || 'left'}
          maxWidth={column?.maxWidth}
          minWidth={column?.minWidth}
          style={{
            width: column?.width,
            ...style
          }}>
          {column?.cellContent ? (
            column.cellContent(rowData, searchQuery, loading)
          ) : (
            <Typography className={classes.defaultText}>
              {rowData[column.dataKey] ? (
                <SHighlightText
                  size="sm"
                  fontColor={TextColor.DarkGrey}
                  searchQuery={searchQuery}
                  value={rowData[column.dataKey]}
                />
              ) : (
                column.placeholder || '-'
              )}
            </Typography>
          )}
        </STableCell>
      ))}
    </>
  );
};

export default TableBodyCells;
